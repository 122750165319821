import React, { Component } from 'react';

export default class Student extends Component {

    render() {
        const {
            value,
        } = this.props;
        return (
            <li>
                { value.name } ({ value.dni })
            </li>
        );
    }

}
