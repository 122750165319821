import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export default class RegisterStudent extends Component {
    state = {
        dni: '',
        name: '',
    };

    onDniChange(event) {
        this.setState({
            dni: event.target.value,
        });
    }

    onNameChange(event) {
        this.setState({
            name: event.target.value,
        });
    }

    onSubmit() {
        const {
            onChange,
        } = this.props;
        onChange({
            dni: this.state.dni,
            name: this.state.name,
        });
    }

    render() {
        return (
            <Container>
                <h1>
                    Registrarse
                </h1>
                <Form>
                    <Form.Group
                        controlId = 'dni'
                        as = { Row }
                    >
                        <Form.Label
                            column
                            sm = '2'
                        >
                            DNI
                        </Form.Label>
                        <Col
                            sm = '10'
                        >
                            <Form.Control
                                type = 'text'
                                placeholder = 'Escribe tu DNI...'
                                value = { this.state.dni }
                                onChange = { this.onDniChange.bind(this) }
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group
                        controlId = 'name'
                        as = { Row }
                    >
                        <Form.Label
                            column
                            sm = '2'
                        >
                            Nombre
                        </Form.Label>
                        <Col
                            sm = '10'
                        >
                            <Form.Control
                                type = 'text'
                                placeholder = 'Escribe tu nombre...'
                                value = { this.state.name }
                                onChange = { this.onNameChange.bind(this) }
                            />
                        </Col>
                    </Form.Group>
                    <Button
                        onClick = { this.onSubmit.bind(this) }
                    >
                        Guardar
                    </Button>
                </Form>
            </Container>
        );
    }

}
