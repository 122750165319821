import React, { Component } from 'react';

import Students from './Students';

export default class SubjectLists extends Component {

    render() {
        const {
            subject,
        } = this.props;
        return (
            <section
                style = {{
                    marginLeft: '32px',
                    marginBottom: '32px',
                }}
            >
                <h3>
                    { subject.name }
                </h3>
                {
                    subject.students.map((list, group) => (
                        <section
                            key = { group }
                            style = {{
                                marginLeft: '32px',
                                marginBottom: '16px',
                            }}
                        >
                            <h5>
                                {
                                    (group === 0)
                                        ? 'Todos'
                                        : 'Grupo ' + group
                                }
                            </h5>
                            <Students
                                value = { list }
                            />
                        </section>
                    ))
                }
            </section>
        );
    }

}
