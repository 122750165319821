import React, { Component } from 'react';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import Changes from './contracts/Changes.json';

import RegisterStudent from './components/RegisterStudent';
import Subjects from './components/Subjects';
import SubjectLists from './components/SubjectLists';

import getWeb3 from './utils/getWeb3';

class App extends Component {
    state = {
        web3: null,
        account: '',
        network: null,
        contract: null,
        student: null,
        subjects: [],
    };

    async componentDidMount() {
        const web3 = await getWeb3();
        const networkId = await web3.eth.net.getId();
        const deployedNetwork = Changes.networks[networkId];
        const instance = new web3.eth.Contract(
            Changes.abi,
            deployedNetwork && deployedNetwork.address,
        );
        this.setState({
            web3,
            network: networkId,
            contract: instance,
        });
        await this.loadData();
        setInterval(this.loadData.bind(this), 1000);
        /*try {
            // Get network provider and web3 instance.
            const web3 = await getWeb3();

            // Use web3 to get the user's accounts.
            const accounts = await web3.eth.getAccounts();
            console.log(accounts);

            // Get the contract instance.
            const networkId = await web3.eth.net.getId();
            const deployedNetwork = SimpleStorageContract.networks[networkId];
            const instance = new web3.eth.Contract(
                SimpleStorageContract.abi,
                deployedNetwork && deployedNetwork.address,
            );

            // Set web3, accounts, and contract to the state, and then proceed with an
            // example of interacting with the contract's methods.
            this.setState({ web3, accounts, contract: instance }, this.runExample);
        } catch (error) {
            // Catch any errors for any of the above operations.
            alert(
                `Failed to load web3, accounts, or contract. Check console for details.`,
            );
            console.error(error);
        }*/
    };

    async loadData() {
        const {
            web3,
            contract: instance,
        } = this.state;
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        let student = await instance.methods.students(account).call();
        if (student.dni === '') {
            student = null;
        }
        const subjectCount = await instance.methods.subjectCount.call();
        const subjects = [];
        for (let i = 0; i < subjectCount; i++) {
            const subject = await instance.methods.subjects(i).call();
            const groups = [];
            for (let group = 0; group <= subject.numberOfGroups; group++) {
                const studentCount = await instance.methods.getGroupStudentCount(i, group).call();
                const students = [];
                for (let j = 0; j < studentCount; j++) {
                    const addr = await instance.methods.getGroupStudentAddress(i, group, j).call();
                    students.push(await instance.methods.students(addr).call());
                }
                groups.push(students);
            }
            subjects.push({
                ...subject,
                students: groups,
            });
        }
        if (student) {
            const groups = [];
            for (let i = 0; i < subjectCount; i++) {
                const group = await instance.methods.getGroupAt(i).call({from: account});
                groups.push(group.toNumber());
            }
            student = {
                ...student,
                groups,
            };
        }
        this.setState({
            account,
            student,
            subjects,
        });

    }

    async addStudent({dni, name}) {
        const {
            contract: instance,
            account,
        } = this.state;
        instance.methods.addStudent(dni, name).send({ from: account });
    }

    runExample = async () => {
        const { accounts, contract } = this.state;

        // Stores a given value, 5 by default.
        await contract.methods.set(5).send({ from: accounts[0] });

        // Get the value from the contract to prove it worked.
        const response = await contract.methods.get().call();

        // Update state with the result.
        this.setState({ storageValue: response });
    };

    render() {
        if (!this.state.web3) {
            return this.loading();
        }
        if (!this.state.student) {
            return (
                <RegisterStudent
                    onChange = { this.addStudent.bind(this) }
                />
            );
        }
        return (
            <Container>
                <h1>Asignaturas</h1>
                <Subjects
                    student = { this.state.student }
                    subjects = { this.state.subjects }
                    account = { this.state.account }
                    contract = { this.state.contract }
                />
                <h1>Listas de alumnos</h1>
                {
                    this.state.subjects.map((subject, id) => (
                        <SubjectLists
                            key = { id }
                            subject = { subject }
                        />
                    ))
                }
            </Container>
        );
    }

    loading() {
        return (
            <Container>
                <Row>
                    <Col sm/>
                    <Col
                        sm = 'auto'
                    >
                        <Spinner
                            animation = 'grow'
                        />
                    </Col>
                    <Col sm/>
                </Row>
            </Container>
        );
    }
}

export default App;
