import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

export default class Subject extends Component {
    state = {
        value: 0,
    };

    enroll() {
        const {
            contract,
            account,
            index,
        } = this.props;
        contract.methods.enroll(index).send({ from: account });
    }

    onGroupChange(event) {
        this.setState({
            value: parseInt(event.target.value),
        });
    }

    requestChange() {
        const {
            contract,
            account,
            index,
        } = this.props;
        if (this.state.value !== 0) {
            contract.methods.requestChange(index, this.state.value).send({ from: account });
        }
    }

    render() {
        const {
            subject,
            group,
        } = this.props;
        return (
            <Card>
                <Card.Body>
                    <Card.Title>
                        { subject.name }
                    </Card.Title>
                    <Card.Subtitle className='mb-2 text-muted'>
                        {
                            (() => {
                                if (group === 0) {
                                    return 'No matriculado';
                                } else if (group < 0) {
                                    return 'Grupo ' + (-group) + ' (Pendiente de cambio)';
                                } else {
                                    return 'Grupo ' + group;
                                }
                            })()
                        }
                    </Card.Subtitle>
                    {
                        (group === 0) && (
                            <Button
                                variant = 'primary'
                                size = 'sm'
                                onClick = { this.enroll.bind(this) }
                            >
                                Matricularse
                            </Button>
                        )
                    }
                    {
                        (group > 0) && (
                            <InputGroup
                                size = 'sm'
                            >
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        Cambiarse a
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    as = 'select'
                                    value = { this.state.value }
                                    onChange = { this.onGroupChange.bind(this) }
                                >
                                    <option
                                        value = {0}
                                        disabled
                                    >
                                        grupo...
                                    </option>
                                    {
                                        [...(new Array(subject.numberOfGroups.toNumber())).keys()].map(x => {
                                            if (x + 1 !== group) {
                                                return (
                                                    <option
                                                        key = {x + 1}
                                                        value = {x + 1}
                                                    >
                                                        {x + 1}
                                                    </option>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                </FormControl>
                                <InputGroup.Append>
                                    <Button
                                        variant = 'outline-secondary'
                                        onClick = { this.requestChange.bind(this) }
                                    >
                                        Enviar
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        )
                    }
                </Card.Body>
            </Card>
        );
    }

}
