import React, { Component } from 'react';

import Student from './Student';

export default class Students extends Component {

    render() {
        const {
            value,
        } = this.props;
        if (value.length === 0) {
            return (
                <span
                    style = {{
                        marginLeft: '16px',
                    }}
                >
                    No hay ningún matriculado aún.
                </span>
            );
        } else {
            return (
                <ol>
                    {
                        value.map((student, id) => (
                            <Student
                                key = { id }
                                value = { student }
                            />
                        ))
                    }
                </ol>
            );
        }
    }

}
