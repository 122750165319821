import React, { Component } from 'react';

import CardColumns from 'react-bootstrap/CardColumns';

import Subject from './Subject';

export default class Subjects extends Component {

    render() {
        const {
            student,
            subjects,
            account,
            contract,
        } = this.props;
        return (
            <CardColumns>
                {
                    subjects.map((subject, index) => {
                        return (
                            <Subject
                                key = { index }
                                index = { index }
                                subject = { subject }
                                student = { student }
                                group = { student.groups[index] }
                                account = { account }
                                contract = { contract }
                            />
                        );
                    })
                }
            </CardColumns>
        );
    }

}
